@import "./globals/site.variables";
@import url("~leaflet/dist/leaflet.css");

.dev {
  background: white !important;
  font-size: 24px !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 9999 !important;
  padding: 10px !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2) !important;
  border: 1px solid #505050 !important;
  border-radius: 4px !important;
}


body {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
  background-color: @headerColor !important;
  min-height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
}


.invenio-page-body {
  background-color: @lightGrey !important;
  padding: 2rem;
  margin: 2rem;
  border-radius: 15px;
  box-shadow: 3px 3px @lapisLazuli, -1em 0 .4em @lapisLazuli;
  min-height: calc(100vh - 150px);
}

.full-height {
  height: calc(100vh - 210px); /* Make the container take full height */
}

.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

dd {
  margin-inline-start: 0 !important;
}

.ui.container {
  width: 100% !important;
}

// footer
.ui.header.footer {
  color: @carrotOrange !important;
}

.ui.link.list .item, .ui.link.list a.item, .ui.link.list .item a:not(.ui) {
  color: white !important;
}

.ui.list > a.item i.icon {
  color: white;
}

.ui.menu .item {
  font-size: medium;
}

.header_menu_item {
  font-size: large !important;
  color: white !important;
}

.ui.menu .item:hover {
  color: @carrotOrange !important;
}

.ui.invenio-header-container {
  background: none !important;
}

#invenio-nav {
  background: none;
}

.logo {
  max-width: 200px !important;
}

::placeholder {
  color: lightgrey !important;
  opacity: 3;
}

.invenio-header-container {
  display: block !important;
  padding-left: 2em !important;
  padding-right: 2em !important;
}

.title-image {
  width: 45%;
  max-height: 50vh;
  overflow: hidden;
}

.title-search-bar {
  width: 25%;
}

@media only screen and (max-width: 1550px) {
  /* Tablet width */
  .title-search-bar {
    width: 35%;
  }
}
@media only screen and (max-width: 1300px) {
  /* Tablet width */
  .title-search-bar {
    width: 45%;
  }
}


@media only screen and (max-width: 1000px) {
  /* Tablet width */
  .title-search-bar {
    width: 55%;
  }
}

@media only screen and (max-width: 768px) {
  /* Mobile width */
  .title-search-bar {
    width: 80%;
  }
}

.input-wrapper {
  position: relative;
  display: inline-block;
}

.input-wrapper input {
  margin-right: 40px !important;
}

.input-wrapper button {
  position: absolute !important;
  right: 0 !important;
}

.title-stats {
  align-items: end !important;
  justify-content: center;
  height: auto;
  margin-top: 2em !important;
  row-gap: 3em;
}

.title-stats i {
  color: #0A2033 !important;
  font-size: 40px !important;
}

.title-stats .item {
  margin: 0 3em 0 3em;
  align-items: center !important;
  justify-content: center;
  height: 130px;
  background-color: white;
  border: 3px solid;
  border-radius: 50%;
  box-shadow: 0 3px 5px rgba(255, 255, 255, 0.55);
  width: 150px !important;
  display: flex !important;
  flex-direction: row !important;
}

.title-communities-container {
  height: 300px;
  background-color: #003972 !important;
  margin-top: 5em !important;
}

.page-nav-container {
  height: auto;
}

.page-nav {
  color: @yaleBlue;
  padding: 1em 2em 0 1em;
}

@media only screen and (max-width: 767px) {
  .mobile-m-t {
    padding-top: 1rem;
  }
}


.search-listing-item {
  width: 100% !important;
  background-color: white !important;
  border-radius: 35px !important;

  &:hover {
    border-left: 4px solid #0A2033 !important;
    border-radius: 0 !important;
    box-shadow: 0 4px 6px rgba(10, 32, 51, 0.2) !important;
    transition: transform 0.1s ease-in-out, border-left 0.1s ease-in-out, box-shadow 0.1s ease-in-out !important;
  }

  .content {
    margin: 2em !important;
  }
}

.results-per-page {
  margin-right: 2rem;

  @media screen and (max-width: 768px) {
    margin-right: 0.5rem;
  }

  @media screen and (max-width: 530px) {
    margin: 0 0 0.5rem;
  }
}

.search-sort, .results-per-page {
  color: @oxfordBlue;
  display: inline-block;

  & > span {
    margin-right: 0.5rem;
  }

  @media screen and (max-width: 1300px) {
    margin: 0;
    text-align: center;

    & > span {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    & > span {
      margin: 0.2rem;
    }
  }

  @media screen and (max-width: 578px) {
    margin: 0;

    & > span {
      margin: 0;
    }
  }
}

.active-filters-labels {
  .ui.label {
    margin: 0.5rem 0.5rem 0.5rem 0;

    &:last-child {
      margin-right: 1rem;
    }
  }
}

.facets-container {
  border-top: 1px solid #9a9a9a;
  padding-top: calc(1rem + 3.15px);
  clear: both;

  .facet-list {
    margin-left: 31px;

    .facets-item {
      display: block;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}

.facet-accordion-content {
  margin-top: 0.5rem;
}

a.accordion-title {
  text-decoration: none;

  p {
    font-weight: 700;
    color: #0A2033;
    display: flex;
    justify-content: space-between;
    line-height: normal;
    word-wrap: anywhere;

    &:hover {
      text-decoration: underline;
    }

    .accordion-dropdown-icon {
      display: inline-block;
      color: @oxfordBlue;
      opacity: 1;
      width: initial;
      height: initial;
      transition: transform 0.1s ease, opacity 0.1s ease, color 0.1s ease;
      text-decoration: none;
    }
  }
}

.facet-wrapper.content:not(.parent) {
  padding: 0.5em 0;
  padding-left: 20px !important;
  display: flex;
  align-items: start;

  .ui.checkbox > label {
    padding-inline-end: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }

  .facet-count {
    min-width: unset;
  }
}

.facet > .content > .header {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  text-transform: initial;
}

.record-detail-container {
  background-color: @lightGrey !important;
  padding: 2rem !important;
}

.scroll-to-top-button {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 2.5rem !important;
  right: 2.5rem;
  z-index: 1000;

  &:hover {
    background: transparent !important;
  }

  &:active {
    background: transparent !important;
  }

  &:focus {
    background: transparent !important;
  }

  .scroll-to-top-text {
    font-weight: 700;
    font-size: 0.7rem;
  }

  @media screen and (max-width: 1679px) {
    bottom: 180px;
  }

  @media screen and (max-width: 768px) {
    right: 25px;
    bottom: 148px;
  }
}

#geo-map {
  width: 100% !important;
  height: 300px !important;
}

.detail-header {
  display: flex;
  flex-direction: row;
  font-weight: 700;
  font-size: 1.3rem;
  gap: 1rem;
}

.borderLess {
  border: none !important;
  box-shadow: none !important;
}

.detail-main-info-text {
  margin-top: 1rem;
  margin-left: 1rem;
}

.detail-main-info {
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.keywords {
  max-height: 100px;
  overflow-y: auto;
}

// UTILS:
.oxfordBlue {
  color: #0A2033 !important;
}

.yaleBlue {
  color: #003972 !important;
}

.lapisLazuli {
  color: #0A5A98 !important;
}

.carrotOrange {
  color: #ED9633 !important;
}

.lightGrey {
  color: #f0f0f0 !important;
}

.bg-oxfordBlue {
  background-color: #0A2033 !important;
}

.bg-yaleBlue {
  background-color: #003972 !important;
}

.bg-lapisLazuli {
  background-color: #0A5A98 !important;
  color: white !important;
}

.bg-carrotOrange {
  background-color: #ED9633 !important;
}

.bg-lightGrey {
  background-color: #f0f0f0 !important;
}

.bg-yellowGreen {
  background-color: #96ce58 !important;
}

.bg-lightBlue {
  background-color: #8dcbe8 !important;
  color: white !important;
}

.white {
  color: white !important;
}

// SIZES

.full-width {
  width: 100% !important;
}

// ScrollBar
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: @lapisLazuli;
  border-radius: 20px;
  border: 2px solid #8dcbe8;
}

::-webkit-scrollbar-thumb:hover {
  background: @oxfordBlue;
}

::-webkit-scrollbar-corner {
  background: #f1f1f1;
}

